<template>
  <b-modal
    id="editarPlano"
    hide-footer
    centered
    size="md"
    :title="`Editar Plano de Contas: ${form.nome}`"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>

      <div class="d-flex">
        <feather-icon icon="AlignCenterIcon" size="19" />
        <h4 class="mb-2 ml-50">Dados do Plano de Contas</h4>
      </div>

      <validation-observer ref="editarPlanoValidation">
        <b-form class="mt-1">
          <b-row>
            <b-col>
              <b-form-group label="Nome *" label-for="nome">
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required|max:80"
                >
                  <b-form-input
                    id="nome"
                    v-model="form.nome"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="submit"
                @click="gravaAlteracoes"
              >
                <b-spinner small v-if="spinner" />
                <span v-if="spinner">Aguarde...</span>
                <span v-else>Cadastrar</span>
              </b-button>

              <b-button variant="outline-secondary" @click="fecharModal">
                Fechar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BAlert,
  VBModal,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import Cleave from "vue-cleave-component";
import store from "@/store";
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["form", "idxN0", "idxN1", "idxN2", "idxN3"],
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BAlert,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      required,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      spinner: false,
      submit: false,
      errors: false,
    };
  },

  methods: {
    gravaAlteracoes() {
      this.$refs.editarPlanoValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;

          this.$http
            .put("v1/planos/" + this.form.id, this.form)
            .then((res) => {
              let response = {
                idxN0: this.idxN0,
                idxN1: this.idxN1,
                idxN2: this.idxN2,
                idxN3: this.idxN3,
                dados: this.form, // res.data
              };
              store.commit("planos/EDITAR_PLANO", response);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Plano de Contas alterado com sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              // this.$store.dispatch("planos/listaPlanos");
              this.$bvModal.hide("editarPlano");
            })
            .catch((error) => {
              this.getErrors = this.$responseMessages.getMessage(error, null);
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },
    fecharModal() {
      this.$bvModal.hide("editarPlano");
    },
  },
};
</script>

<style lang="scss">
</style>
