<template>
  <b-row>
    <b-col cols="12">
      <b-card
        style="padding: 50px"
        v-for="(plano, index0) in planos"
        :key="plano.id"
      >
        <template #header>
          <b-col style="margin-left: 5px">
            <h3 class="font-weight-bold">{{ plano.nome }}</h3>
          </b-col>

      <!--<b-col>
            <b-button
              variant="gradient-primary"
              class="mb-0 float-right"
              @click="cadastrarPlano(plano, index0, -1, -1)"
              >Nova Categoria {{ plano.nome }}
            </b-button>
          </b-col> -->
        </template>

        <b-card-title
          v-for="(planoN1, index1) in plano.filhos"
          :key="planoN1.id"
        >
          <b-row align-v="center">
            <b-col style="margin: 10px 0 20px 20px">
              <span class="text-primary"
                >{{ planoN1.numero }} {{ planoN1.nome }}</span
              >
            </b-col>
            <!--  <b-col class="text-right" style="margin-right: 20px">
              <b-button
                class="btn-icon"
                variant="flat-primary"
                @click="cadastrarPlano(planoN1, index0, index1, -1)"
              >
                <feather-icon icon="PlusIcon" size="16" />
              </b-button>
              <b-button
                class="btn-icon"
                variant="flat-secondary"
                @click="editarPlano(planoN1, index0, index1, -1, -1)"
              >
                <feather-icon icon="Edit2Icon" size="16" />
              </b-button>
              <b-button
                class="btn-icon text-danger"
                variant="flat-secondary"
                @click="excluirPlano(planoN1.id, index0, index1, -1, -1)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
              </b-button>
            </b-col> -->
          </b-row>
          <b-list-group class="mb-3">
            <template v-for="(planoN2, index2) in planoN1.filhos">
              <b-list-group-item class="item1" :key="planoN2.id">
                <b-row align-v="center">
                  <b-col>
                    <h5>{{ planoN2.numero }} {{ planoN2.nome }}</h5>
                  </b-col>
                  <b-col class="text-right btn-item1">
                    <b-button
                      class="btn-icon"
                      variant="flat-primary"
                      @click="cadastrarPlano(planoN2, index0, index1, index2)"
                    >
                      <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                    <!--    <b-button
                      class="btn-icon"
                      variant="flat-secondary"
                      @click="editarPlano(planoN2, index0, index1, index2, -1)"
                    >
                      <feather-icon icon="Edit2Icon" size="16" />
                    </b-button>
                    <b-button
                      class="btn-icon text-danger"
                      variant="flat-secondary"
                      @click="
                        excluirPlano(planoN2.id, index0, index1, index2, -1)
                      "
                    >
                      <feather-icon icon="Trash2Icon" size="16" />
                    </b-button> -->
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item
                class="item2"
                v-for="(planoN3, index3) in planoN2.filhos"
                :key="planoN3.id"
              >
                <b-row align-v="center">
                  <b-col>
                    <h5>{{ planoN3.numero }} {{ planoN3.nome }}</h5>
                  </b-col>
                  <b-col class="text-right">
                    <b-button
                      class="btn-icon"
                      variant="flat-secondary"
                      @click="
                        editarPlano(planoN3, index0, index1, index2, index3)
                      "
                    >
                      <feather-icon icon="Edit2Icon" size="16" />
                    </b-button>
                    <b-button
                      class="btn-icon text-danger"
                      variant="flat-secondary"
                      @click="
                        excluirPlano(planoN3.id, index0, index1, index2, index3)
                      "
                    >
                      <feather-icon icon="Trash2Icon" size="16" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-card-title>
      </b-card>
    </b-col>

    <ModalEditarPlano
      :form="editar"
      :idxN0="idxN0"
      :idxN1="idxN1"
      :idxN2="idxN2"
      :idxN3="idxN3"
    />
    <ModalCadastrarPlano
      ref="CadastrarPlanoComponent"
      :idxN0="idxN0"
      :idxN1="idxN1"
      :idxN2="idxN2"
    />
  </b-row>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardTitle,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";
import ModalEditarPlano from "./ModalEditarPlano.vue";
import ModalCadastrarPlano from "./ModalCadastrarPlano.vue";

import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";

export default {
  components: {
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardTitle,
    BFormCheckbox,
    ModalEditarPlano,
    ModalCadastrarPlano,
    ToastificationContent,
    StatisticCardHorizontal,
  },
  data() {
    return {
      configEditar: [],
      editar: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ["numero", "nome"],
      boxTwo: "",
      idxN0: -1,
      idxN1: -1,
      idxN2: -1,
      idxN3: -1,
      fields: [{ key: "nome", label: "Nome", sortable: true }],
    };
  },
  computed: {
    ...mapState({
      planos: (state) => state.planos.planos,
    }),
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.$store.dispatch("planos/listaPlanos");
  },

  methods: {
    cadastrarPlano(plano, idxN0, idxN1, idxN2) {
      this.idxN0 = idxN0;
      this.idxN1 = idxN1;
      this.idxN2 = idxN2;

      this.$refs.CadastrarPlanoComponent.SetTipoDespesa(plano.dre_id < 7);
      this.$refs.CadastrarPlanoComponent.SetPlanoPai(plano);
      // this.$refs.CadastrarPlanoComponent.SetOrdem(plano.filhos[plano.filhos.length - 1].ordem + 1);

      this.$bvModal.show("cadastrarPlano");
    },
    editarPlano(plano, idxN0, idxN1, idxN2, idxN3) {
      this.idxN0 = idxN0;
      this.idxN1 = idxN1;
      this.idxN2 = idxN2;
      this.idxN3 = idxN3;
      this.editar = plano;
      this.$bvModal.show("editarPlano");
    },
    buscaDados() {
      this.$store.dispatch("planos/listaPlanos");
    },
    excluirPlano(id, idxN0, idxN1, idxN2, idxN3) {
      this.idxN0 = idxN0;
      this.idxN1 = idxN1;
      this.idxN2 = idxN2;
      this.idxN3 = idxN3;
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir esse Plano de Contas ?", {
          title: "Excluir Plano de Contas",
          size: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$http
              .delete("adm/planos/" + id)
              .then((res) => {
                let response = {
                  idxN0: this.idxN0,
                  idxN1: this.idxN1,
                  idxN2: this.idxN2,
                  idxN3: this.idxN3,
                  dados: res.data,
                };
                this.$store.commit("planos/DELETAR_PLANO", response);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Plano de Contas excluído com sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
                //   this.$store.dispatch("planos/listaPlanos");
              })
              .catch((error) => {
                this.$responseMessages.getMessage(error, null);
              });
          }
        });
    },
  },
};
</script>
<style scoped>
.item1 {
  padding-left: 20px;
}
.item2 {
  padding-left: 40px;
}
</style>
